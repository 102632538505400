export default function growl(data) {

  let type = data.type;
  let message = data.message;
  let time = data.time || 5000;

  // Обработчик клика
  let clickHandler = event => {

    // Клик на growl__item
    if (event.target.closest('.growl__item')) {
      event.target.closest('.growl__item').remove();
    }
  };
  document.addEventListener('click', clickHandler);

  let parent = document.querySelector('.growl');

  if (!parent) {
    parent = document.createElement('div');
    parent.className = 'growl';
    document.body.appendChild(parent);
  }

  let msg;

  msg = document.createElement('div');
  msg.className = 'growl__item growl__item--' + type;

  msg.innerHTML = [
    '<i class="growl__ico"></i>',
    '<div class="growl__text">' + message + '</div>'
  ].join('');



  if (parent.querySelector('.growl__item')) {
    parent.insertBefore(msg, parent.querySelector('.growl__item'));
  } else {
    parent.appendChild(msg);
  }

  let close = function(element) {

    setTimeout(function() {

      if (element.firstElementChild) {
        element.lastElementChild.classList.add('growl__item--collapsed');
      }


      setTimeout(function() {
        if (element.firstElementChild) {
          element.lastElementChild.remove();
        } else {
          element.remove();
        }

      }, 450);

    }, time);

  };


  if (parent.children.length + 1 > 4) {
    close(parent, 0);
  }



  // msg.offsetTop;
  setTimeout( () => {
    msg.classList.add('growl__item--shown');
  }, 50);

  close(parent, time);
}