import { configureStore } from '@reduxjs/toolkit'
import { reducer } from './rootReducer'
import createSagaMiddleware from 'redux-saga';

import watcherCheckToken from "./sagas/checkToken";
import watcherSignInSubmit from "./sagas/signInSaga";
import watcherGetCoursesList from "./sagas/getCoursesListSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware)
});

export default store;

sagaMiddleware.run(watcherCheckToken);
sagaMiddleware.run(watcherSignInSubmit);
sagaMiddleware.run(watcherGetCoursesList);