import React, { useEffect } from 'react';
import './courses.css';
import { useDispatch, useSelector } from "react-redux";
import { getCoursesListSagaAction } from "../../redux/sagaActions";
import Course from "../../components/Course/Course";

const Courses = () => {
    const dispatch = useDispatch();
    const courses = useSelector(state => state.courses);

    useEffect(() => {
        dispatch(getCoursesListSagaAction());
    }, []);

    return (
        <div className="courses">
            {
                courses.map(elem => (<Course key={ elem.id } data={ elem }/> ))
            }
        </div>
    );
};

export default Courses;