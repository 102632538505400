import { takeLatest, put, call, select } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { setCoursesAction } from "../rootReducer";
import { url } from "../../config";
import growl from "../../lib/growl/growl";
import { setLocalStorage } from "../../helpers/LocalStorage";

const request = data => {
    return fetch(`${ url }/get_courses_list`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerGetCoursesList() {
    const user = yield select(state => state.user);
    try {
        let formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('hash', user.hash);

        const response = yield call (request, formData);

        if (response.void) {
            setLocalStorage('workouter_web', '').then(() => window.location.reload());
        }
        else if (response.success) {
            yield put(setCoursesAction(response.courses));
        }
        else if (response.error) {
            growl({ type: 'error', message: response.message });
        }
    }
    catch(error) {
        console.log(error);
    }
}

export default function* watcherGetCoursesList() {
    yield takeLatest(saga_types.GET_COURSES_LIST_SAGA, workerGetCoursesList);
}