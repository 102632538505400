import { takeLatest, put, call } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { setUserAction, startAppAction } from "../rootReducer";
import { url } from "../../config";
import { getLocalStorage, setLocalStorage } from "../../helpers/LocalStorage";

const request = data => {
    return fetch(`${ url }/check_token`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerCheckTokenSaga() {
    try {
        const token = yield getLocalStorage('workouter_web');
        let formData = new FormData();
        formData.append('token', token || '');

        const response = yield call (request, formData);

        if (response.void) {
            setLocalStorage('workouter_web', '').then();
            yield put(startAppAction());
        }
        else if (response.success) {
            // Добавляю user в redux
            yield put(setUserAction(response.user));
            yield put(startAppAction());
        }
    }
    catch(error) {
        console.log(error);
        yield put(startAppAction());
    }
}

export default function* watcherCheckToken() {
    yield takeLatest(saga_types.CHECK_TOKEN_SAGA, workerCheckTokenSaga);
}