import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Preloader from "./components/Preloader/Preloader";
import { checkTokenSagaAction } from "./redux/sagaActions";
import Auth from "./pages/Auth/Auth";
import Main from "./pages/Main/Main";
import Courses from "./pages/Courses/Courses";
import Header from "./components/Header/Header";

const App = () => {
    const dispatch = useDispatch();
    const loader = useSelector(state => state.loader);
    const startApp = useSelector(state => state.startApp);
    const user = useSelector(state => state.user);

    const router = createBrowserRouter([
        {
            path: '/',
            element:
                <>
                    <Header/>
                    <div className="content">
                        <Main />
                    </div>
                </>,
        },
        {
            path: '/courses',
            element:
                <>
                    <Header/>
                    <div className="content">
                        <Courses />
                    </div>
                </>

        }
    ]);

    useEffect(() => {
        dispatch(checkTokenSagaAction());
    }, []);

    if (!startApp) {
        return <Preloader/>
    }

    if (!user.id) {
        return <Auth/>
    }

    return (
        <div className="">
            { loader && <Preloader/> }
            <RouterProvider router={ router } />
        </div>
    );
};

export default App;
