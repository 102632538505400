import { takeLatest, put, call } from 'redux-saga/effects';
import { saga_types } from "../sagaTypes";
import { loaderAction, setUserAction } from "../rootReducer";
import { url } from "../../config";
import { setLocalStorage } from "../../helpers/LocalStorage";
import growl from "../../lib/growl/growl";

const request = data => {
    return fetch(`${ url }/signin`, {
        method: 'post',
        body: data
    })
        .then(response => response.json()).catch(error => console.log(error))
};

function* workerSignInSaga(args) {
    yield put(loaderAction(true));
    try {
        let formData = new FormData();
        formData.append('email', args.data.email);
        formData.append('password', args.data.password);

        const signin = yield call (request, formData);

        if (signin.success) {
            // Добавляем hash в локальное хранилище
            setLocalStorage('workouter_web', signin.user.hash).then();
            yield put(setUserAction(signin.user));
            growl({ type: 'success', message: signin.message });
        }
        else if (signin.error) {
            growl({ type: 'error', message: signin.message });
        }
    }
    catch(error) {
        console.log(error);
        yield put(loaderAction(false));
    }
    finally {
        yield put(loaderAction(false));
    }
}

export default function* watcherSignInSubmit() {
    yield takeLatest(saga_types.SIGN_IN_SAGA, workerSignInSaga);
}