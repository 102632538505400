export const getLocalStorage = async key => {
    try {
        return await localStorage.getItem(key);
    }
    catch (error) {
        console.log(error);
    }
};

export const setLocalStorage = async (key, data) => {
    try {
        await localStorage.setItem(key, data);
    }
    catch (error) {
        console.log(error);
    }
};