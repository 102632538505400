import React from 'react';
import './header.css';
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserAction } from "../../redux/rootReducer";
import { USER_INITIAL } from "../../redux/initialStateConstant";
import { setLocalStorage } from "../../helpers/LocalStorage";

const Header = () => {
    const dispatch = useDispatch();

    return (
        <header className="header">
            <div className="header__navs">
                <NavLink
                    to={ '/' }
                    className={ ({ isActive }) => {
                            if (isActive) return 'header__nav header__nav--active';
                            else return 'header__nav';
                        }
                    }
                >Главная</NavLink>
                <NavLink
                    to={ '/courses' }
                    className={ ({ isActive }) => {
                        if (isActive) return 'header__nav header__nav--active';
                        else return 'header__nav';
                    }
                    }
                >Курсы</NavLink>
            </div>
            <div className="header__profile">
                <div
                    className="header__signout"
                    onClick={ () => {
                        if (!window.confirm('Вы действительно хотите выйти?')) return;
                        dispatch(setUserAction(USER_INITIAL));
                        setLocalStorage('workouter_web', '').then();
                    } }
                >Выход</div>
            </div>
        </header>
    );
};

export default Header;