import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

const rootReducer = createSlice({
    name: 'reducer',
    initialState,
    reducers: {
        loaderAction: (state, action) => { state.loader = action.payload },
        startAppAction: state => { state.startApp = true },
        setUserAction: (state, action) => { state.user = action.payload },
        setCoursesAction: (state, action) => { state.courses = action.payload }
    }
});

export const reducer = rootReducer.reducer;
export const {
    loaderAction, startAppAction, setUserAction, setCoursesAction
} = rootReducer.actions;